<style scoped lang="less">
  .patient-query {

  }
  .search-res {
    max-height: 300px;
    overflow: auto;
    margin-top: 10px;
    ul, li {
      padding: 0;
      margin: 0;
      list-style: none;
    }
    li {
      transition: all .3s;
      border: 1px solid #eee;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background-color: #f8f8f8;
      }
      & + li {
        margin-top: 5px;
      }
    }
    li:last-child {display: none;}
    li:only-child {display: list-item;}
  }
</style>

<template>
  <div class="patient-query">
    <fm-input-new placeholder="请输入搜索内容" v-model="data.key">
      <fm-select style="width: 90px;" slot="prepend" v-model="data.type">
        <fm-option value="name" label="姓名"></fm-option>
        <fm-option value="phone" label="手机号"></fm-option>
      </fm-select>
      <span style="cursor: pointer;" @click="query()" slot="append">查询</span>
    </fm-input-new>
    <div class="search-res">
      <ul>
        <li v-for="item in searchList" :key="item.id" @click="$emit('query', item)">
          <div style="display: flex;">
            <div>
              <span style="color: #666;">姓名: </span><span style="color: #333;">{{item.name}}</span>
              <span style="color: #333;margin-left: 10px;">{{item.sex}}</span>
              <span style="color: #333;margin-left: 10px;">{{item.age}}岁</span>
            </div>
          </div>
          <div style="margin-top: 10px;"><span style="color: #666;">手机号: </span><span style="color: #333;">{{item.phone}}</span></div>
          <div style="color: #999;margin-top: 10px;">
            <span style="color: #666;">身份证: </span>
            <span style="color: #333;">{{item.idNo}}</span>
          </div>
        </li>
        <li>暂无数据</li>
      </ul>
    </div>
    <fm-page
      style="margin-top: 10px;"
      :total="total"
      :current="search.pageNum"
      :pageSize="search.pageSize"
      @change="query"
      showTotal
    />
  </div>
</template>

<script>
import { patientRequest } from '@/api'

export default {
  data () {
    return {
      data: {
        key: null,
        type: 'name'
      },
      total: 0,
      search: {
        pageSize: 1,
        pageNum: 1
      },
      searchList: []
    }
  },
  methods: {
    async query (page) {
      if (page) {
        this.search.pageNum = page
      } else {
        this.search.pageNum = 1
      }
      if (this.data.key) {
        const res = await patientRequest.get(Object.assign({
          [this.data.type]: this.data.key
        }, this.search))
        this.searchList = res.data
        this.total = res.total
      } else {
        this.$notice.warning('请输入搜索内容')
      }
    }
  }
}
</script>
