<style scoped lang="less">
  .btns {
    display: flex;
    justify-content: space-around;
  }
</style>

<template>
  <fm-form label-width="100px">
    <fm-form-item label="生产日期">
      <FmDatePicker :value="putData.productionDate" @change="(date) => {putData.productionDate = date;}" placeholder="请选择生产日期" format="Y-M-D"></FmDatePicker>
    </fm-form-item>
    <fm-form-item label="有效期至">
      <FmDatePicker :value="putData.expiredDate" @change="(date) => {putData.expiredDate = date;}" placeholder="请选择有效期至" format="Y-M-D"></FmDatePicker>
    </fm-form-item>
    <fm-form-item label="生产批号">
      <fm-input-new placeholder="请输入生产批号" v-model="putData.productionBatch" />
    </fm-form-item>
    <fm-form-item label="采购价">
      <fm-input-number v-model="putData.price" />
    </fm-form-item>
    <fm-form-item label="数量">
      <fm-input-number v-model="putData.volume" />
    </fm-form-item>
    <!-- <fm-form-item label="储存地">
      <fm-input-new placeholder="请输入储存地" v-model="putData.description" />
    </fm-form-item> -->
    <div class="btns">
      <fm-btn @click="submit">入库</fm-btn>
      <fm-btn @click="$emit('close')">取消</fm-btn>
    </div>
  </fm-form>
</template>

<script>
import { inventoryDetailRequest } from '@/api'
export default {
  props: {
    data: { type: Object, default: null }
  },
  data () {
    return {
      putData: {
        productionDate: null,
        productionBatch: null,
        price: null,
        volume: null,
        description: null
      }
    }
  },
  methods: {
    async submit () {
      const data = Object.assign({
        type: 'in',
        medicineId: this.data.medicineId
      }, this.putData)
      await inventoryDetailRequest.add(data)
      this.$notice.success('入库成功')
      this.$emit('success', data)
    }
  },
}
</script>
