<style lang="less" scoped>
  .medicine-list {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    box-sizing: border-box;
    max-height: 125px;
    overflow: auto;
    .item {
      box-sizing: border-box;
      padding: 5px;
      width: 25%;
      .wrap {
        background-color: #F8F8F8;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 10px;
      }
    }
  }  
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .name {
    flex: 1;
    width: 0;
    font-weight: 800;
    color: #000;
  }
  .row {
    display: flex;justify-content: space-between;margin: 5px 0;
  }
  .title {
    font-size: 18px;
    color: #000;
    margin-left: 10px;
  }
</style>

<template>
  <!-- 共享药房采购 -->
  <div>
    <div>
      <div class="block" v-if="nonexistent.length">
        <div class="title">药房未配置药品</div>
        <div class="medicine-list scrollbar">
          <div class="item" v-for="item in nonexistent" :key="item.id" :class="{processed: processed.includes(item.id)}">
            <div class="wrap">
              <div class="row">
                <div class="name ellipsis" :title="item.name">{{item.name}}</div>
                <div class="price">{{item.price}}元</div>
              </div>
              <div class="row">
                <span>{{item.packing}}</span>
                <span>{{item.manufacturer}}</span>
              </div>
              <div class="row">
                <span>{{item.type}}</span>
                <fm-btn v-if="!processed.includes(item.id)" @click="openAdd(item)" size="small">新增</fm-btn>
                <span v-else style="color:#0066ff;">已处理</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block" v-if="nostock.length">
        <div class="title">药房无库存药品</div>
        <div class="medicine-list scrollbar">
          <div class="item" v-for="item in nostock" :key="item.id" :class="{processed: processed.includes(item.id)}">
            <div class="wrap">
              <div class="row">
                <div class="name ellipsis" :title="item.mainbodyMedicine.medicineName || item.name">{{item.mainbodyMedicine.medicineName || item.name}}</div>
                <div class="price">{{item.mainbodyMedicine.price || item.price}}元</div>
              </div>
              <div class="row">
                <span>{{item.mainbodyMedicine.medicinePacking || item.packing}}</span>
                <span>{{item.mainbodyMedicine.medicineManufacturer || item.manufacturer}}</span>
              </div>
              <div class="row">
                <span>{{item.mainbodyMedicine.medicineType || item.type}}</span>
                <fm-btn v-if="!processed.includes(item.id)" @click="openPut(item)" size="small">入库</fm-btn>
                <span v-else style="color:#0066ff;">已处理</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="shareMedicines.length">
      <div style="display: flex;align-items: center;">
        <fm-title title-text="请选择共享药房"></fm-title>
        <fm-select clearable filterable @change="onShareMainBodyGroupIdChange" v-loadingx="loading.next" v-model="mainbodyMedicineGroupId">
          <fm-option v-for="item in shareMainBodyGroupList" :key="item.id" :label="item.mainbodyLabel" :value="item.id"></fm-option>
        </fm-select>
      </div>
      <fm-checkbox-group v-if="shareMedicineList.length" v-model="checkedMedicineList" style="flex-wrap: wrap;">
        <fm-checkbox v-for="item in shareMedicineList" :key="item.medicine.id" :disabled="!item.share" :label="item.medicine.id">
          {{item.medicine.name}} /
          <template v-if="item.share">
            {{item.share.price}}元
          </template>
          <template v-else>
            未售此药
          </template>
        </fm-checkbox>
      </fm-checkbox-group>
      <div v-else>请选择共享药房</div>
    </div>

    <div style="display: flex;justify-content: flex-end;">
      <fm-btn @click="localSubmit" long>诊所开药</fm-btn>
      <fm-btn @click="shareSubmit" v-if="shareMedicineList.length" long>共享药房开药</fm-btn>
    </div>

    <fm-modal :value="status.add" title="新增药品" width="70vw" @cancel="status.add = false" :mask-closable="false">
      <PharmacyForm v-if="status.add" :medicine-id="chooseData.id" @submit="onAdd" />
    </fm-modal>

    <fm-modal :value="status.put" title="入库" width="400px" @cancel="status.put = false" :mask-closable="false">
      <PharmacyPut v-if="status.put" @success="onPut" @close="status.put = false" :data="chooseData" />
    </fm-modal>
  </div>
</template>

<script>
import { mainbodyMedicineRequest, mainbodyMedicineGroupRequest } from '@/api'
import PharmacyForm from '@/views/pharmacy/form.vue'
import PharmacyPut from '@/views/pharmacy/put.vue'

export default {
  components: {
    PharmacyForm, PharmacyPut
  },
  props: {
    data: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      loading: {
        next: false
      },
      status: {
        add: false,
        put: false
      },
      processed: [],
      chooseData: null,
      mainbodyMedicineGroupId: null,
      shareMainBodyGroupList: [],
      shareMedicineList: [],
      checkedMedicineList: []
    }
  },
  watch: {
    data () {
      this.processed = []
      this.mainbodyMedicineGroupId = null
      this.loadShareMainBodyGroupList()
    }
  },
  computed: {
    nostock () {
      return this.data.filter(v => v.mainbodyMedicine && (!v.inventory || !v.inventory.totalVolume))
    },
    nonexistent () {
      return this.data.filter(v => !v.mainbodyMedicine)
    },
    shareMedicines () {
      return this.data.filter(v => v.type === '中药颗粒瓶装')
    }
  },
  methods: {
    async onShareMainBodyGroupIdChange () {
      if (this.mainbodyMedicineGroupId && this.shareMedicines.length) {
        const res = await mainbodyMedicineRequest.get({
          mainbodyMedicineGroupId: this.mainbodyMedicineGroupId,
          medicineIds: this.shareMedicines.map(v => v.id).join(',')
        })
        this.shareMedicineList = this.shareMedicines.map(medicine => {
          return {
            medicine: medicine,
            share: res.find(v => v.medicineId === medicine.id)
          }
        })
      } else {
        this.shareMedicineList = []
      }
      this.checkedMedicineList = []
    },
    async loadShareMainBodyGroupList () {
      this.loading.next = true
      this.shareMainBodyGroupList = await mainbodyMedicineGroupRequest.getBuy({type: 'share'})
      this.mainbodyMedicineGroupId = null
      this.shareMedicineList = []
      this.loading.next = false
    },
    openAdd (data) {
      this.chooseData = data
      this.status.add = true
    },
    openPut (data) {
      this.chooseData = { medicineId: data.id }
      this.status.put = true
    },
    async onAdd (data) {
      const res = await mainbodyMedicineRequest.add(data)
      this.processed.push(res.medicineId)
      this.$notice.success('添加成功')
      this.status.add = false
    },
    onPut ({ medicineId }) {
      this.processed.push(medicineId)
      this.status.put = false
    },
    localSubmit () {
      this.$emit('submit', {
        type: 'local',
        details: this.nonexistent,
        nonexistent: this.nonexistent
      })
    },
    shareSubmit () {
      if (!this.mainbodyMedicineGroupId) {
        this.$notice.warning('请选择共享药房')
      } else if (!this.checkedMedicineList.length) {
        this.$notice.warning('请选择药品')
      } else {
        this.$emit('submit', {
          type: 'share',
          saleMainbodyId: this.shareMainBodyGroupList.find(v => v.id === this.mainbodyMedicineGroupId).mainbodyId,
          mainbodyMedicineGroupId: this.mainbodyMedicineGroupId,
          details: this.checkedMedicineList.map(id => this.shareMedicineList.find(v => v.medicine.id === id)),
          nonexistent: this.nonexistent
        })
      }
    }
  },
}
</script>
