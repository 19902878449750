<style scoped></style>

<template>
  <div>
    <fm-form :inline="3">
      <fm-form-item label="药品">
        <!-- <fm-select v-model="data.medicineId" @change="medicineChange" clearable filterable placeholder="请选择药品">
          <fm-option v-for="item in medicines" :key="item.id" :value="item.id" :label="item.name"></fm-option>
        </fm-select> -->
        {{medicineData ? medicineData.name : ''}}
        <fm-btn style="margin-left: 10px;" v-if="!medicineId" @click="status.choose = true" :long="!data.medicineId">{{data.medicineId ? '重选药品' : '请选择药品'}}</fm-btn>
      </fm-form-item>
      <template v-if="data.medicineId === null">
        <fm-form-item label="药品名称">
          <fm-input-new v-model="newMedicine.medicineName" />
        </fm-form-item>
        <fm-form-item label="药品类型">
          <fm-select v-model="newMedicine.medicineType" clearable filterable placeholder="请选择药品类型">
            <fm-option v-for="item in types" :key="item.value" :value="item.value" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="生产厂家">
          <fm-input-new v-model="newMedicine.medicineManufacturer" />
        </fm-form-item>
        <fm-form-item label="包装规格">
          <fm-input-new v-model="newMedicine.medicinePacking" />
        </fm-form-item>
      </template>
      <fm-form-item label="平均采购价">
        <fm-input-number v-model="data.avgPurchasePrice" />
      </fm-form-item>
      <fm-form-item label="零售价">
        <fm-input-number v-model="data.price" />
      </fm-form-item>
      <fm-form-item label="库存预警值">
        <fm-input-number v-model="data.warningValue" />
      </fm-form-item>
      <fm-form-item label="生产批号">
        <fm-input-new placeholder="请输入生产批号" v-model="data.productionBatch" />
      </fm-form-item>
      <fm-form-item label="生产日期">
        <FmDatePicker :value="data.productionDate" @change="(date) => {data.productionDate = date;}" placeholder="请选择生产日期" format="Y-M-D"></FmDatePicker>
      </fm-form-item>
      <fm-form-item label="有效期至">
        <FmDatePicker :value="data.expiredDate" @change="(date) => {data.expiredDate = date;}" placeholder="请选择有效期至" format="Y-M-D"></FmDatePicker>
      </fm-form-item>
      <!-- <fm-form-item label="储存地">
        <fm-input-new placeholder="请输入储存地" v-model="data.description" />
      </fm-form-item> -->
      <fm-form-item label="库存量">
        <fm-input-number v-model="data.volume" />
      </fm-form-item>
    </fm-form>
    <fm-btn long @click="submit">确定</fm-btn>
    <fm-modal v-model="status.choose" title="药品选择" width="1000px">
      <MedicineChoose choose v-if="status.choose" @choose="onMedichineChoose" style="height: 70vh;" />
    </fm-modal>
  </div>
</template>

<script>
import { medicineRequest } from '@/api'
import MedicineChoose from '@/views/medicine/index'

import { types } from '@/views/medicine/lib'

export default {
  components: {
    MedicineChoose
  },
  props: {
    medicineId: { type: Number, default: null }
  },
  data () {
    return {
      medicineData: null,
      newMedicine: {
        medicineName: null,
        medicineType: null,
        medicineManufacturer: null,
        medicinePacking: null
      },
      data: {
        mainbodyId: null,
        medicineId: null,
        avgPurchasePrice: null,
        price: null,
        warningValue: null,
        productionBatch: null,
        productionDate: null,
        expiredDate: null,
        // description: null,
        volume: null
      },
      status: {
        choose: false
      }
    }
  },
  computed: {
    types () {
      return types
    }
  },
  watch: {
    medicineId (id) {
      this.loadMedicineData(id)
    },
    medicineData: {
      deep: true,
      handler (data) {
        this.data.medicineId = data.id
        this.data.price = data.price
      }
    }
  },
  methods: {
    onMedichineChoose (data) {
      this.medicineData = data
      this.status.choose = false
    },
    submit () {
      let data = JSON.parse(JSON.stringify(this.data))
      if (this.data.medicineId) {
        this.$emit('submit', data)
      } else {
        this.$emit('submit', Object.assign(data, this.newMedicine))
      }
    },
    async loadMedicineData (id) {
      id = id || this.medicineId
      if (id) {
        const res = await medicineRequest.get({ id })
        if (res.length) {
          this.medicineData = res[0]
        }
      }
    }
  },
  mounted () {
    this.loadMedicineData(this.medicineId)
  }
}
</script>
