/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Date: Fri Nov 12 2021
 */
import { usage, usageFrequency, drippingSpeed, demand, measurement } from '@/views/medicine/lib.js'
import Vue from 'vue'

function valueUnitSplit (value) {
  const split = value.split('/')
  return [Number(split[0]), split.slice(1).join('/')]
}

export class Prescription {
  constructor (type, data = null) {
    this.type = type
    if (data) {
      this.instructions = data.instructions
      this.usageFrequency = data.usageFrequency
      this.dripSpeed = type === '输液' && data.dripSpeed ? valueUnitSplit(data.dripSpeed) : data.dripSpeed
      this.timesNum = data.timesNum
      this.demand = data.demand
      this.startTime = data.startTime
      this.dayNum = data.dayNum

      this.list = data.medicineData.map(v => Vue.observable(new PrescriptionMedicine(type, null, v)))
    } else {
      this.instructions = usage[0]
      this.usageFrequency = usageFrequency[0]
      this.dripSpeed = type === '输液' ? [1, drippingSpeed[0].value] : null
      this.timesNum = 0
      this.demand = type === '中药饮片' ? demand[0] : null
      this.startTime = null
      this.dayNum = 1
  
      this.list = []
    }
  }

  getData () {
    return {
      type: this.type,
      instructions: this.instructions,
      usageFrequency: this.usageFrequency,
      dripSpeed: Array.isArray(this.dripSpeed) ? this.dripSpeed.join('/') : this.dripSpeed,
      timesNum: this.timesNum,
      demand: this.demand,
      startTime: this.startTime,
      dayNum: this.dayNum,
      medicineData: this.list.map(v => {
        return v.getData()
      })
    }
  }

  addMedichine (medichine, tag, volume, origin) {
    const index = this.list.findIndex(v => v.medicineId === medichine.id && v.origin.type == origin.type)
    if (index > -1) {
      if (['中药颗粒袋装', '中药颗粒瓶装'].includes(tag.value)) {
        const equivalent = medichine.equivalent || medichine.medichineEquivalent
        const weight = medichine.weight || medichine.medichineWeight
        const unit = medichine.unit || medichine.medichineUnit

        Vue.set(this.list[index], 'equivalent', Number((this.list[index].equivalent + equivalent).toFixed(2)))
        Vue.set(this.list[index], 'dose', Number((this.list[index].equivalent / equivalent * weight).toFixed(2)))
        Vue.set(this.list[index], 'volume', unit === 'g' ? Number((this.list[index].equivalent / equivalent).toFixed(2)) : Math.ceil(this.list[index].equivalent / equivalent))
      } else {
        Vue.set(this.list[index], 'volume', this.list[index].volume + (volume || 1))
      }
      Vue.set(this.list[index], 'amount', Number((this.list[index].volume * this.list[index].price).toFixed(2)))
    } else {
      const p = Vue.observable(new PrescriptionMedicine(this.type, medichine))

      p.medicineUsage = this.instructions
      p.usageFrequency = this.usageFrequency

      if (this.timesVolume) {
        p.timesVolume = this.timesVolume
      }
      p.volume = volume || p.volume
      p.origin = origin || { type: 'local' }
      this.list.push(p)
    }

    if (this.list.length && this.timesNum < 1) {
      this.timesNum = 1
    }
  }

  delMedichine (index) {
    this.list.splice(index, 1)
    if (this.list.length < 1 && this.timesNum >= 1) {
      this.timesNum = 0
    }
  }

  setMedicineUsage () {
    this.list.forEach(v => v.medicineUsage = this.instructions)
  }
  setUsageFrequency () {
    this.list.forEach(v => v.usageFrequency = this.usageFrequency)
  }
}

export class PrescriptionMedicine {
  constructor (type, medichine, data = null) {
    this.type = type

    this.prescriptionId = null
    this.origin = { type: 'local' }
    if (data) {
      this.medichine = data.medicine ? data.medicine : { name: data.medicineName, id: data.medicineId }
      this.medicineId = data.medicineId
      this.dose = data.dose
      this.volume = data.volume
      this.price = data.price
      this.amount = data.amount
      this.medicineUsage = data.medicineUsage
      this.usageFrequency = data.usageFrequency
      this.skinTest = data.skinTest

      if (['中药颗粒袋装', '中药颗粒瓶装'].includes(type)) {
        this.equivalent = this.volume * this.medichine.equivalent
      } else {
        this.equivalent = 0
      }
  
      if (data.timesVolume && (type === '输液' || type === '中西成药')) {
        this.timesVolume = valueUnitSplit(data.timesVolume)
      } else {
        this.timesVolume = null
      }
    } else {
      this.medichine = medichine
      this.medicineId = medichine.id
      this.amount = medichine.mainbodyMedicine ? medichine.mainbodyMedicine.price : medichine.price
      this.medicineUsage = usage[0]
      this.usageFrequency = usageFrequency[0]
      this.skinTest = '否'

      if (['中药颗粒袋装', '中药颗粒瓶装'].includes(type)) {
        const equivalent = medichine.equivalent || medichine.medichineEquivalent
        const weight = medichine.weight || medichine.medichineWeight
        const unit = medichine.unit || medichine.medichineUnit

        this.equivalent = medichine.equivalent
        this.dose = Number((this.equivalent / equivalent * weight).toFixed(2))
        this.volume = unit === 'g' ? Number((this.equivalent / equivalent).toFixed(2)) : Math.ceil(this.equivalent / equivalent)
        this.price = Number((this.volume * (medichine.mainbodyMedicine ? medichine.mainbodyMedicine.price : medichine.price)).toFixed(2))
      } else {
        this.equivalent = 0
        this.dose = medichine.singleDose
        this.volume = 1
        this.price = medichine.mainbodyMedicine ? medichine.mainbodyMedicine.price : medichine.price
      }
  
      if (type === '输液' || type === '中西成药') {
        this.timesVolume = [1, measurement[0]]
      } else {
        this.timesVolume = null
      }
    }
  }

  getData () {
    return {
      prescriptionId: this.prescriptionId,
      medicineId: this.medicineId,
      dose: this.dose,
      volume: this.volume,
      price: this.price,
      amount: this.amount,
      medicineUsage: this.medicineUsage,
      usageFrequency: this.usageFrequency,
      skinTest: this.skinTest,
      timesVolume: Array.isArray(this.timesVolume) ? this.timesVolume.join('/') : this.timesVolume
    }
  }
}
